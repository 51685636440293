import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';

import {FcCurrencyExchange} from 'react-icons/fc';
import {WiDaySunnyOvercast} from 'react-icons/wi';
import {RiWhatsappFill} from 'react-icons/ri';
import { FaWind } from "react-icons/fa";
import { FaWater } from "react-icons/fa";

import Divisas from '../Divisas';
import Card from "../meteorologia/components/Card";

import PlanillaVientos from "../vientos/PlanillaVientos";
import PlanillaMareas from "../mareas/PlanillaMareas";


import '../../stylesheets/Inicio.css';

import imagen from '../../imagenes/portada.jpeg';


const estiloMonedas = {
	fontSize: '52px',
	marginTop: '0',
	marginLeft: '0px',
  };

  const estiloMareas = {
	fontSize: '52px',
	marginTop: '0',
	marginLeft: '0px',
  };
const estiloClima = {
	fontSize: '52px',
	fontStyle:'bold',
	marginTop: '0',
	marginLeft: '0',

}

const estiloVientos = {
	fontSize: '52px',
	fontStyle:'bold',
	marginTop: '0',
	marginLeft: '0',

}
const estiloWapp = {
	fontSize: '52px',
	marginTop: '0',
	marginLeft: '0',
	color: 'green'
}
  const estiloFlecha = {
	fontSize: '32px',
	marginTop: '0',
	marginLeft: '13px',
  };



const Inicio = () => {
	let urlWeather = "https://api.openweathermap.org/data/2.5/weather?appid=259f2b920a937219df483ea2aa24bcb3&lang=es";
    let cityUrl = "&q=Búzios";
    let urlForecast = "https://api.openweathermap.org/data/2.5/forecast?appid=259f2b920a937219df483ea2aa24bcb3&lang=es";

	
	const [show, setShow] = useState(false);
	const [show2, setShow2] = useState(false);
	const [show4, setShow4] = useState(false);

//....   hooks de weatherpanel ......
const [weather, setWeather] = useState([]);
const [forecast, setForecast] = useState([]);
const [loading, setLoading] = useState(false);
const [show3, setShow3] = useState(false);
//........................................................
//... hooks de worldtides ..........................
const [tides, setTides] = useState([]); // Guardar datos de mareas
const [showMareas, setShowMareas] = useState(false); // Controlar visibilidad de Offcanvas de mareas


	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const handleClose2 = () => setShow2(false);
	const handleShow2 = () => {
		getLocation();
		setShow2(true)
	};	
	const handleClose4 = () => setShow4(false);
	const handleShow4 = () => {
		getLocation();
		setShow4(true)
	};	
	
	//.......  control de apertura y cierre mareas...
	const handleCloseMareas = () => setShowMareas(false);
	const handleShowMareas = () => {
		getTides(); // Llama a la función que obtiene las mareas
		setShowMareas(true);
	};



	const envioWapp = () => {
		const url = 'https://wa.me/5492932540996?text=Tengo%20interes%20en%20sus%20servicios';
		window.open(url, '_blank');

	};

	const getLocation = async() => {
			setLoading(true); //para visualizar el spinner de espera
	
			//weather
			urlWeather = urlWeather + cityUrl; //completamos la url con localizacion de ciudad
	
			await fetch(urlWeather).then((response) => {
				if(!response.ok) throw {response}
				return response.json();
			}).then((weatherData) => {
				console.log("dato que llega de weather: ",weatherData);
				setWeather(weatherData);
			}).catch(error => {
				console.log(error);
				setLoading(false);  // si hay error deja de cargar y no se visualiza nada
				setShow3(false);
			});
	
			//forecast
			urlForecast = urlForecast + cityUrl; //completamos la url con localizacion de ciudad
	
			await fetch(urlForecast).then((response) => {
				if(!response.ok) throw {response}
				return response.json();
			}).then((forecastData) => {
				console.log("dato que llega de forecast: ",forecastData);
				setForecast(forecastData);
	
				setLoading(false);
				setShow3(true);  //con todos los datos  ya podemos visualizar la ventana
			}).catch(error => {
				console.log(error);
				setLoading(false);  // si hay error deja de cargar y no se visualiza nada
				setShow3(false);
			});	
		}	
	const getTides = async() => {

		// Parámetros de la API y ubicación de Búzios
			const apiKey = '7d302956-7ecc-11ef-95ed-0242ac130004-7d3029ce-7ecc-11ef-95ed-0242ac130004';
			const lat = -22.7575;  // Latitud de Búzios
			const lng = -41.8819;  // Longitud de Búzios

			// Obtener la fecha actual en formato Unix timestamp
			const startDate = Math.floor(new Date().setHours(0, 0, 0, 0) / 1000); // Inicio del día
			const endDate = Math.floor(new Date().setHours(23, 59, 59, 999) / 1000); // Fin del día

			setLoading(true); //para visualizar el spinner de espera


			let urlTides = `https://api.stormglass.io/v2/tide/extremes/point?lat=${lat}&lng=${lng}&start=${startDate}&end=${endDate}`;
		
			await fetch(urlTides,
				{
					headers: {
					  'Authorization': apiKey
					}
				  }
			).then((response) => {
				if (!response.ok) throw {response};
				return response.json();
			}).then((tidesData) => {
				console.log("dato que llega de mareas: ", tidesData);
				const extremes = tidesData.data;

				extremes.forEach(extreme => {
const horaMarea = new Date(extreme.time).toLocaleTimeString(); // Formatea la hora
const alturaMarea = extreme.height.toFixed(2); // Altura de la marea con dos decimales
					
					// Mostrar la hora y altura de cada marea
				
					console.log(`Marea: ${extreme.type}, Altura: ${alturaMarea} metros, Hora: ${horaMarea}`);
				  });
			//	setTides(tidesData.heights); // Guarda las alturas de mareas
				setTides(tidesData);
				setLoading(false);
				setShowMareas(true); //con todos los datos podemos visualizar la ventana
			}).catch(error => {
				console.log(error);
				setLoading(false);
				setShowMareas(false);
			});
		};		

	return (
		<div  className="paginaInicio">

		<img className="imagenPortada" src={imagen} alt="portada" />


		<Button variant="primary" onClick={handleShow}  className="botonConversion" style={{display: "none"}}>
        <FcCurrencyExchange style={estiloMonedas}/> 
        </Button>		

		<Offcanvas show={show} onHide={handleClose} style={{overflow: "scroll"}}>
			<Offcanvas.Header closeButton   >
			<Offcanvas.Title  style={{fontSize : "0.9rem", position:"relative", textAlign:"center"}}>Con Buzios Maravilloso podes cacular la conversión de tu dinero actual.</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<div className='contenido_Divisas'>		
				<Divisas />
				</div>
			</Offcanvas.Body>
		</Offcanvas>

		<Button variant="primary" onClick={handleShow2}  className="botonMeteorologia">
        <WiDaySunnyOvercast style={estiloClima}/>
        </Button>		

		<Offcanvas show={show2} onHide={handleClose2} style={{overflow: "scroll"}}>
			<Offcanvas.Header closeButton>
			<Offcanvas.Title  style={{fontSize : "0.9rem", position:"relative"}}></Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<div className='contenido_Meteorologia'>		
				<Card 
               showData = {show3}
               loadingData = {loading}
               weather = {weather}
               forecast = {forecast}
                     />				
			    </div>
			</Offcanvas.Body>
		</Offcanvas>	

		<Button variant="primary" onClick={handleShow4}  className="botonVientos">
        <FaWind style={estiloVientos}/>
        </Button>		

		<Offcanvas show={show4} onHide={handleClose4} style={{overflow: "scroll"}}>
			<Offcanvas.Header closeButton>
			<Offcanvas.Title  style={{fontSize : "0.9rem", position:"relative"}}></Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<div className='contenido_Meteorologia'>		
				<PlanillaVientos 
               showData = {show4}
               loadingData = {loading}
               weather = {weather}
               forecast = {forecast}
                     />				
			    </div>
			</Offcanvas.Body>
		</Offcanvas>

		<Button variant="primary" onClick={handleShowMareas} className="botonConversion">
			{/* Aquí puedes agregar un ícono relacionado con el agua o el mar, si deseas */}
			<FaWater  style={estiloMareas}/>
		</Button>

		<Offcanvas show={showMareas} onHide={handleCloseMareas} style={{overflow: "scroll"}}>
			<Offcanvas.Header closeButton>
				<Offcanvas.Title style={{fontSize : "0.9rem", position:"relative"}}>Mareas en Búzios</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<div className='contenido_Meteorologia'>
					<PlanillaMareas 
						showData={showMareas}
						loadingData={loading}
						tides={tides} // Pasa los datos de mareas al componente
					/>
				</div>
			</Offcanvas.Body>
		</Offcanvas>
			

		<Button   onClick={envioWapp}  className="botonWhatsapp  mx-auto">
        <RiWhatsappFill style={estiloWapp}/> 
        </Button>		

		</div>
		)
}


export default Inicio