import ContadorVisitas from '../visitas/ContadorVisitas';

import '../../stylesheets/Footer.css';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {

   




	return(
        <footer className="footer">

            <a href="https://instagram.com/buziosmaravilloso?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer  nofollow"><InstagramIcon sx={{ color: "#fff" }} /></a>
            <a href="https://www.facebook.com/buzioshospedaje" target="_blank" rel="noopener noreferrer nofollow"><FacebookIcon sx={{ color: "#fff" }} /></a>           
            <div className="derechos-de-autor">Creado por<a href="https://lgcsoluciones.com/" target="_blank" rel="noopener noreferrer">LGC Soluciones</a>(2022) &#169;</div>
        </footer>
		)
}

export default Footer;